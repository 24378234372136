/* Input */
.ant-input {
  @apply text-white;
}
.ant-input::placeholder {
  @apply text-gray2;
}

/* Checkbox */
.ant-checkbox-checked .ant-checkbox-inner {
  @apply bg-transparent;
}

.ant-checkbox-group-item {
  @apply text-gray2;
}

/* Radio */
.ant-radio-wrapper {
  @apply text-gray2;
}

/* Switch */
.ant-switch {
  @apply !bg-black;
}
.ant-switch-checked {
  @apply !bg-green;
}
