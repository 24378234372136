@import './antd.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  @apply flex grow flex-col;
}

body {
  @apply m-0 bg-black font-sans text-[14px] font-medium text-white;
}

*,
::before,
::after {
  @apply box-border border-0 border-solid border-current;
}
